import React from 'react'
import { lawyers, services as servicesData } from '../assets/data'
import FooterSection from '../sections/FooterSection'
import MainTemplate from './MainTemplate'
import styled from 'styled-components'
import PhoneIcon from '../assets/icons/phone.inline.svg'
import EmailIcon from '../assets/icons/email.inline.svg'
import ServicesDetails from '../sections/ServicesDetails'
import { Helmet } from 'react-helmet'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto 2rem;
  max-width: 1100px;
  width: 95%;
  img {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
    img {
      width: 50%;
      padding-right: 2rem;
      object-fit: cover;
    }
  }
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 26px;
  }
  img {
    width: 220px;
  }
`

const Contact = styled.div`
  width: 100%;
  a {
    text-decoration: none;
    color: inherit;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 2px solid ${(props) => props.theme.colors.gold};
    display: flex;
    align-items: center;
    @media screen and (min-width: 900px) {
      &:first-of-type {
        justify-content: flex-start;
      }
      &:last-of-type {
        justify-content: flex-end;
      }
    }
    svg {
      width: 25px;
      margin: 0 10px 0 0;
      color: ${({ theme }) => theme.colors.gold};
    }
  }
`

const ServiceLayout = styled.div`
  width: 90%;
  max-width: 1100px;
  margin: 0 auto 100px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`
const ServiceColumn = styled.div`
  width: 100%;
  @media screen and (min-width: 900px) {
    width: 50%;
  }
`
const ServiceTemplate = ({ lawyerId }) => {
  const lawyer = lawyers.find((el) => el.id === lawyerId)
  const services = servicesData.find((service) => service.lawyerId === lawyerId).services

  return (
    <MainTemplate>
      <Helmet>
        <title>
          {lawyer.prefix} {lawyer.name} | Usługi
        </title>
        <meta name="description" content={lawyer.description} />
      </Helmet>
      <Container>
        <img src={lawyer.photo} alt={lawyer.name} />
        <InfoContainer>
          <h2>
            {lawyer.prefix} {lawyer.name}
          </h2>
          <img src={lawyer.logo} alt={lawyer.name} />
          <Contact>
            <a href={`tel:${lawyer.phone}`}>
              <PhoneIcon />
              {lawyer.phone}
            </a>
            <a href={`mailto:${lawyer.email}`}>
              <EmailIcon />
              {lawyer.email}
            </a>
          </Contact>
        </InfoContainer>
      </Container>
      <ServiceLayout>
        <ServiceColumn>
          {services.map(
            (service, index) =>
              !!(index % 2) && <ServicesDetails key={service.slug} service={service} />
          )}
        </ServiceColumn>
        <ServiceColumn>
          {services.map(
            (service, index) =>
              !!!(index % 2) && <ServicesDetails key={service.slug} service={service} />
          )}
        </ServiceColumn>
      </ServiceLayout>
      <FooterSection />
    </MainTemplate>
  )
}
export default ServiceTemplate
