import React from 'react'
import styled from 'styled-components'

const Headline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.1rem 1rem;
  margin: 2rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gold};
  box-shadow: 0 0 3px 0 ${({ theme }) => theme.colors.gold};
  svg {
    width: 30px;
  }
`

const ServicesDetails = ({ service }) => {
  return (
    <div id={service.slug}>
      <Headline>
        <h4>{service.title}</h4>
        {service.icon}
      </Headline>
      <ul>
        {service.scopeDetail.map((line, i) => (
          <li key={`${line}${i}`}>{line}</li>
        ))}
      </ul>
    </div>
  )
}

export default ServicesDetails
